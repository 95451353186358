import API from './axios';

export function returnkey(userNo: string, mobile: string) {
  return API
    .post("/clientApi/ApiKey/returnApiKey", {
      userNo,
      mobile,
    });
}

export function getkey(userNo: string, mobile: string, token: string) {
  return API({
    method: 'post',
    url: '/clientApi/ApiKey/getApiKey',
    data: {
      userNo,
      mobile,
    },
    headers: {
      'Access-Token': token
    }
  });
}

export function changekey(userNo: string, mobile: string, token: string) {
  return API({
    method: 'post',
    url: '/clientApi/ApiKey/changeKey',
    data: {
      userNo,
      mobile,
    },
    headers: {
      'Access-Token': token
    }
  });
}



