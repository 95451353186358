import { useState } from 'react';
import { useEventBus } from '../utils/events';

export function useIsLogin() {
  const [isLogin, setIsLogin] = useState(false)
  useEventBus('changeLoginState', (state) => {
    setIsLogin(() => !!state)
  });

  return {
    isLogin
  }
}
