import {
  Badge,
  Button,
  Center,
  Container,
  Group,
  SimpleGrid,
  Text,
  TextInput,
  NumberInput,
  Image,
} from "@mantine/core";
import { useForm } from '@mantine/form';
import { useEffect, useState } from "react";
import {
  IconLoader2,
} from "@tabler/icons-react";
import { Logo } from "../components/Logo";
import { notifications } from "@mantine/notifications";
import { login, sendsmscode, getImgCode } from "../utils/login";
import { useTimer } from '../hooks/countDown';
import './IndexRoute.css';
import events from '../utils/events';
import { useUserInfo } from '../hooks/useUserInfo';
import { useIsLogin } from '../hooks/isLogin';
import { ServeInfo } from '../components/ServeInfo';
import WechatICode from '../assets/wechatcode.png';

interface FromType {
  mobile: string
  verifyCode: string
  captchaCode: string
}

export function IndexRoute() {
  const [submitting, setSubmitting] = useState(false);
  const { num: senCodeTime, start: startSendTime } = useTimer(60, () => {
    setisSendCode(true)
  })
  const [isSendCode, setisSendCode] = useState(true)

  const { isLogin } = useIsLogin()
  const [userInfo, setUserInfo] = useUserInfo()

  const form = useForm<FromType>({
    initialValues: {
      mobile: '',
      verifyCode: '',
      captchaCode: '',
    },

    validate: {
      mobile: (value) => (/^1\d{10}$/.test(value) ? null : '请输入正确手机号'),
      captchaCode: value => value ? null : '请输入图形验证码',
      verifyCode: value => value ? null : '请输入短信验证码',
    },
  });

  const [uuid, setUuid] = useState('');
  const [imgCode, changeImgCode] = useState('')

  const handlerImgCode = async () => {
    changeImgCode(() => '')
    const { code, data }: any = await getImgCode()
    if (code === 200) {
      changeImgCode(() => data.captcha)
      setUuid(() => data.uuid)
    }
  }
  useEffect(() => {
    handlerImgCode()
  }, [])

  const sendCode = async () => {
    const { hasError: hasError1 } = form.validateField('mobile');
    const { hasError: hasError2 } = form.validateField('captchaCode');
    if (!hasError1 && !hasError2) {
      const res: any = await sendsmscode(form.values.mobile, form.values.captchaCode, uuid).catch((error) => {
        if (error.message === "Network Error") {
          notifications.show({
            title: "Error",
            color: "red",
            message: "No internet connection.",
          });
        } else {
          notifications.show({
            title: "Error",
            color: "red",
            message: error.message,
          });
        }
      })
      if (res?.code === 200) {
        setisSendCode(false)
        startSendTime()
        notifications.show({
          title: "Success",
          message: "短信验证码发送成功",
        });
      } else if (res?.message) {
        notifications.show({
          title: "Error",
          color: "red",
          message: res.message,
        });
      }
    }
  }

  const handlerSubmit: any = async (values: FromType) => {
    setSubmitting(true);
    const res: any = await login(values.mobile, values.verifyCode, values.captchaCode, uuid)
      .catch((error) => {
        if (error.message === "Network Error") {
          notifications.show({
            title: "Error",
            color: "red",
            message: "No internet connection.",
          });
        } else {
          notifications.show({
            title: "Error",
            color: "red",
            message: error?.message,
          });
        }
      }).finally(() => {
        setSubmitting(false);
      })
    if (res?.code === 200) {
      form.reset();
      if (res.data.isExpired) {
        notifications.show({
          title: "Error",
          color: "red",
          message: '会员已过有效期，请前往“AI广场”小程序申请会员。',
        });
        return false
      }
      events.emit('changeLoginState', true);
      setUserInfo(() => ({
        userid: res.data.userId,
        mobile: values.mobile,
        userNo: res.data.userNo,
        token: res.data.token
      }))
    } else if (res?.message) {
      notifications.show({
        title: "Error",
        color: "red",
        message: res.message,
      });
    }
  }

  return (
    <>
      {
        isLogin ?
          <ServeInfo /> :
          <Center py="xl" sx={{ height: "100%" }}>
            <Container size="sm">
              <Badge mb="lg">GPT-4 Ready</Badge>
              <Text>
                <Logo style={{ maxWidth: 240 }} />
              </Text>
              <Text mt={4} size="xl">
                合规、稳定、增值
              </Text>
              <Group mt={30} position="center">
                <Text mt={4} size="sm" color="red">
                  <>请先使用绑定手机号码的微信扫码注册，取得会员资格!</>
                </Text>
              </Group>
              <Group mt={20} position="center">
                <img src={WechatICode} style={{ width: '200px' }} />
              </Group>
              <Group mt={50} position="center">
                <form onSubmit={form.onSubmit(handlerSubmit)}>
                  <SimpleGrid cols={1} style={{ width: 260 }}>
                    <TextInput
                      label="会员的手机号码"
                      withAsterisk
                      {...form.getInputProps('mobile')}
                    />
                    <TextInput
                      label="输入图形验证码"
                      withAsterisk
                      {...form.getInputProps('captchaCode')}
                      rightSection={
                        <>
                          <Image
                            fit="fill"
                            src={imgCode}
                            alt=""
                            width={75}
                            height={36}
                            withPlaceholder
                            onClick={handlerImgCode}
                            placeholder={<IconLoader2 className="IconLoader2" size={20} />}
                          />
                        </>
                      }
                    />
                    <TextInput
                      label="输入短信验证码"
                      withAsterisk
                      {...form.getInputProps('verifyCode')}
                      rightSection={
                        <>
                          <Button disabled={!isSendCode} style={{ width: '108px' }} onClick={sendCode}>
                            <>{!isSendCode ? senCodeTime : '发送验证码'}</>
                          </Button>
                        </>
                      }
                    />
                    <Button type="submit" loading={submitting}>
                      确定
                    </Button>
                  </SimpleGrid>
                </form>
              </Group>
              <Group mt={30} position="center">
                <Text mt={4} size="sm">
                  <>继续使用旧版本(<Text c="blue" component="a" href="https://www.evtech.site">https://www.evtech.site</Text>)</>
                </Text>
              </Group>
            </Container>
          </Center>
      }
    </>
  );
}
