import { useLocalStorage } from "@mantine/hooks";

export function useUserInfo() {
  return useLocalStorage({
    key: "user-info",
    defaultValue: {
      userid: '',
      mobile: '',
      userNo: '',
      token: '',
    },
  });
}
