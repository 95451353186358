import globalAxios from 'axios';
import { API_URL } from "./base";

const instance = globalAxios.create({
	baseURL: API_URL
})

// 添加请求拦截器
instance.interceptors.request.use(
	config => {
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
// 添加响应拦截器
instance.interceptors.response.use(
	response => {
		const { status, data } = response
		if (status === 200) {
			return data
		}
		return Promise.reject(response)
	},
	error => {
		return Promise.reject(error)
	}
)

export default instance
