import API from './axios';
import { API_URL } from "./base";

export function login(mobile: string, verifyCode: string, captchaCode: string, uuid: string) {
  return API
    .post("/clientApi/sign/signIn", {
      mobile,
      verifyCode,
      captchaCode,
      uuid,
    })
}

export function sendsmscode(mobile: string, captchaCode: string, uuid:string) {
  return API
    .post("/clientApi/sms/sendVerifyCode", {
      mobile,
      captchaCode,
      uuid,
    })
}

export function getImgCode() {
  return API
    .get("/clientApi/captcha/getCode")
}
