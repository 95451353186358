import {
    Button,
    Modal,
    Stack,
    Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { cloneElement, ReactElement } from "react";
import { useNavigate } from "@tanstack/react-location";

import events from '../utils/events';
import { useIsLogin } from '../hooks/isLogin';
import { useUserInfo } from '../hooks/useUserInfo';

export function LoginModal({ children }: { children: ReactElement }) {
    const { isLogin } = useIsLogin()
    const [opened, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();
    const [userInfo, setUserInfo, removeUserInfo] = useUserInfo()

    const handlerOpen = () => {
        if (isLogin) open()
        else {
            navigate({ to: `/` });
        }
    }

    const logout = () => {
        events.emit('changeLoginState', false);
        removeUserInfo()
        close()
    }

    return (
        <>
            {cloneElement(children, { onClick: handlerOpen })}
            <Modal opened={opened} onClose={close} title="Logout">
                <Stack>
                    <Text size="sm">是否退出登录？</Text>
                    <Button type="submit" color="red" onClick={logout}>
                        确定
                    </Button>
                </Stack>
            </Modal>
        </>
    );
}
